//代理商等级接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-03-8
 */

//代理商等级分页查询
export function agentGradePageList(parameter){
    return axios({
        url: '/agentGrade/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//代理商等级详情
export function agentGradeDetail(parameter){
    return axios({
        url: '/agentGrade/detail',
        method: 'post',
        params: parameter 
    })
}
//代理商等级修改状态
export function agentGradeDelete(parameter){
    return axios({
        url: '/agentGrade/delete',
        method: 'post',
        data: parameter
    })
}
//代理商等级新增
export function agentGradeAdd(parameter){
    return axios({
        url: '/agentGrade/add',
        method: 'post',
        data: parameter
    })
}
//代理商等级编辑
export function agentGradeEdit(parameter){
    return axios({
        url: '/agentGrade/edit',
        method: 'post',
        data: parameter
    })
}
